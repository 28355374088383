/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/first */

/*

adapted from https://github.com/prestaconcept/PrestaImageBundle/blob/v1.7.5/Resources/public/js/cropper.js
*/

/**
 * Include cropper.js.
 *
 * We have to do some webpack magic and inject the correct variables to cropper
 * since we want to use jquery from the window object (not from npm) because Sonata
 * relies on it being loaded in the head.
 * - Cropper expects `this` to be the window object
 * - overwrite require to prevent accidental include of jquery
 * - set define to false for cropper only
 */
require('imports-loader?this=>window,exports=>false,require=>function (){},define=>false!cropper')

import 'cropper/dist/cropper.css'
import '../../../../../web/bundles/prestaimage/css/cropper.css'
import Toast from '../components/jquery/Toast'

/* eslint-disable */

const $ = window.$
const w = window
const DEFAULT_ASPECT_RATIO = '1.78'; // This is default value, as it is used as the initial value for AspectRatio in ImageCropType for the University logo.
let previewLogoWidth = null

    // @TODO: Wert aus Konfiguration auslesen.
const maxUploadSizeInBytes = 2000000

/**
 * @param {jQuery} $el
 * @constructor
 */
const Cropper = function($el) {
    this.$el = $el
    this.options = $.extend({}, $el.data('cropper-options'))

    this.initElements()
        .initLocalEvents()
        .initRemoteEvents()
        .initCroppingEvents()
}

/**
 * @returns {Cropper}
 */
Cropper.prototype.initElements = function () {
    this.$modal = this.$el.find('.modal')
    this.$modal.detach()
    $('body').append(this.$modal)
    this.$aspectRatio = this.$modal.find('input[name="cropperAspectRatio"]')
    this.$input = this.$el.find('input.cropper-base64')
    this.$logoAspectRatio = document.querySelector('.js-university-logo-aspect-ratio')

    /**
     * Local state to store whether an image has been cropped for uploading.
     * This needed in order to clear the hidden field after modal is closed if no
     * image has been cropped.
     */
    this.isImageCropped = false

    this.$container = {
        $preview: this.$modal.find('.js-cropper-preview'),
        $canvas: this.$el.find('.js-cropper-canvas-container'),
        $actions: this.$el.find('.js-image-actions')
    }

    this.$local = {
        $btnUpload: this.$el.find('.js-cropper-local button'),
        $input: this.$el.find('.js-cropper-local input[type="file"]')
    }

    this.$remote = {
        $btnUpload: this.$el.find('.js-cropper-remote button'),
        $uploadLoader: this.$el.find('.js-cropper-remote .remote-loader'),
        $input: this.$el.find('.js-cropper-remote input[type="url"]')
    }

    this.options = $.extend(this.options, {
        aspectRatio: this.$aspectRatio.val(),
        dragMode: 'move',
        autoCropArea: .9
    })

    this.expectedWidth = this.$el.data('max-width')
    this.expectedHeight = this.$el.data('max-height')

    return this
}

/**
 * @returns {Cropper}
 */
Cropper.prototype.initLocalEvents = function () {
    var self = this

    // map virtual upload button to native input file element
    this.$local.$btnUpload.on('click', function () {
        self.$local.$input.trigger('click')
    })

    // start cropping process on input file "change"
    this.$local.$input.on('change', function () {
        var reader = new FileReader()

        // check uploaded file size
        var fileSizeInByte = this.files[0].size

        if (fileSizeInByte > maxUploadSizeInBytes) {
                // @TODO: Text in die Systemtexte zur übersetzung auslagern.
            self.$local.$input.parent().append('<div class="help-block"><span class="text-warning"><i class="mdi mdi-alert-circle "></i> Bitte beachten Sie die maximale Dateigröße</span></div>')
        } else {
            // show a croppable preview image in a modal
            reader.onload = function (e) {
                self.prepareCropping(e.target.result)

                // clear input file so that user can select the same image twice and the "change" event keeps being triggered
                self.$local.$input.val('')
                self.$local.$input.parent().find('.help-block').remove()
            }
        }

        // trigger "reader.onload" with uploaded file
        reader.readAsDataURL(this.files[0])
    })

    return this
}

/**
 * @returns {Cropper}
 */
Cropper.prototype.initRemoteEvents = function () {
    var self = this

    var $btnUpload = this.$remote.$btnUpload
    var $uploadLoader = this.$remote.$uploadLoader

    // handle distant image upload button state
    this.$remote.$input.on('change, input', function () {
        var url = $(this).val()

        self.$remote.$btnUpload.prop('disabled', url.length <= 0 || url.indexOf('http') === -1)
    })

    // start cropping process get image's base64 representation from local server to avoid cross-domain issues
    this.$remote.$btnUpload.on('click', function () {
        $btnUpload.hide()
        $uploadLoader.removeClass('hidden')
        $.ajax({
            url: $btnUpload.data('url'),
            data: {
                url: self.$remote.$input.val()
            },
            method: 'post'
        }).done(function (data) {
            const imageData = data.base64
            self.prepareCropping(data.base64)
            $btnUpload.show()
            $uploadLoader.addClass('hidden')
        })
    })

    return this
}

/**
 * @returns {Cropper}
 */
Cropper.prototype.initCroppingEvents = function () {
    let aspectRatio = null
    var self = this
    if (this.$input.val() !== "") {
        self.render()
    }

    // handle image cropping
    this.$modal.find('[data-method="getCroppedCanvas"]').on('click', function () {
        if (self.$logoAspectRatio) {
            self.$logoAspectRatio.value = aspectRatio || DEFAULT_ASPECT_RATIO
        }
        self.crop()
        aspectRatio = null
    })

    // handle "aspectRatio" switch
    self.$aspectRatio.on('change', function () {
        let previewLogo = document.querySelector('.c-cropper-utilities__preview-logo')
        let croppedWidth = aspectRatio || DEFAULT_ASPECT_RATIO
        if (previewLogo && previewLogoWidth) {
            previewLogo.style.width = (previewLogoWidth / croppedWidth) + 'px'
        }
        aspectRatio = $(this).val()
        self.$container.$preview.children('img').cropper('setAspectRatio', aspectRatio)
    })

    // handle corpper action buttons
    this.$modal.find('.cropper-actions button').on('click', function () {
        const $cropperImage = self.$container.$preview.children('img')
        const action = $(this).data('action')
        const option = $(this).data('option')
        const optionTwo = $(this).data('2nd-option')
        $cropperImage.cropper(action, option, optionTwo)

            //@TODO: Das kann man sicher noch irgendwie generischer lösen.
        // ratio switch
        if (action == 'setAspectRatio') {
            if (!$(this).attr('disabled')){
                const enableAction = $(this).data('enables')
                const expectedHeight = $cropperImage.data('expected-height')
                const expectedWidth = $cropperImage.data('expected-width')
                $(`[data-action-name="${enableAction}"]`).removeAttr('disabled')
                $(this).attr('disabled', '')
                $cropperImage.data('expected-height', expectedWidth)
                $cropperImage.data('expected-width', expectedHeight)
                self.setCropperPreviews($cropperImage)
            }
        }

        // check for zoom higher 100% and throw warning
        self.checkForExtensiveZoom($cropperImage)
    })

    return this
}

/**
 * Open cropper "editor" in a modal with the base64 uploaded image.
 *
 * @param {string} base64
 */
Cropper.prototype.prepareCropping = function(base64) {
    var self = this

    // clean previous croppable image
    this.$container.$preview.children('img').cropper('destroy').remove()

    // reset "aspectRatio" buttons
    this.$aspectRatio.each(function () {
        var $this = $(this)

        if ($this.val().length <= 0) {
            $this.trigger('click')
        }
    })

    this.$modal
        .one('shown.bs.modal', function () {
            // add additional preview images to the modal
            const matchingHiddenInput = $(`#${self.$el[0].id}_base64`)
            const additionalPreviews = matchingHiddenInput.data('cropper-previews')
            const additionalRatios = matchingHiddenInput.data('cropper-ratios')
            const ratioSwitch = $(this).find('.cropper-ratios')
            const additionalPreviewsContainer = $(this).find('.cropper-previews')
            const minImageSize = matchingHiddenInput.data('cropper-min-size')
            let entryType = null;

            if ($('.js-update-entry-type').length) {
                entryType = $('.js-update-entry-type')[1].value
            }

            additionalPreviewsContainer.empty()

            if (additionalPreviews) {
                $.each(additionalPreviews, function (index, previewData) {
                    $.each(previewData, function (size, label) {
                        const isPremiumImage = label.indexOf('nur bei Premium-Eintrag & Anzeige')

                        if (entryType == 2 && isPremiumImage != -1) {
                            var showImagePreview = false
                        } else {
                            var showImagePreview = true
                        }

                        if (showImagePreview) {
                            var splittedSize = size.split('x')
                            previewLogoWidth = splittedSize[0]
                            additionalPreviewsContainer.append(`
                            <div
                                class="c-cropper-utilities__preview c-cropper-utilities__preview-logo"
                                style="width: ${splittedSize[0]}px; height: ${splittedSize[1]}px;"
                            >
                                <div
                                    class="c-cropper-utilities__preview-mask"
                                    style="width: ${splittedSize[0]}px; height: ${splittedSize[1]}px;"
                                >
                                </div>
                            </div>
                            <span class="c-cropper-utilities__preview-label small-text">${label}</span>
                        `)
                        }
                    })
                })
            }

            // check if ratio change should be enabled
            if (additionalRatios) {
                ratioSwitch.removeClass('hidden')
                $.each(additionalRatios, function(index, ratioData) {
                    $.each(ratioData, function(key, ratio) {
                        ratioSwitch.find('[data-format-name="'+key+'"]').attr('data-option', ratio)
                    })
                })
            } else {
                ratioSwitch.addClass('hidden')
            }

            // Set preview containers in cropper options
            self.options.preview = '.cropper-previews .c-cropper-utilities__preview div'

            // (re)build croppable image once the modal is shown (required to get proper image width)
            $('<img>')
                .attr('src', base64)
                .on('load', function () {
                    $(this).cropper(self.options)
                    $(this).on('ready', function () {
                        var cropperImage = $(this).cropper('getImageData')
                        const cropperBox = $(this).cropper('getCropBoxData')

                        $(this).cropper('zoomTo', (cropperBox.width / cropperImage.naturalWidth))

                        cropperImage = $(this).cropper('getImageData')

                        if (cropperImage.height < cropperBox.height) {
                            $(this).cropper('zoomTo', (cropperBox.height / cropperImage.naturalHeight))
                        }

                        // check image size for images with minimal size requirements
                        if (minImageSize) {
                            // check image size and throw error if image is to small
                            const loadedImage = new Image();
                            loadedImage.src = base64;
                            loadedImage.onload = function(){
                                const minWidth = minImageSize.width
                                const minHeight = minImageSize.height
                                const toastMessageWrapper = $('.js-toasts')
                                const toastMessage = $('.js-min-size-toast')

                                if ((loadedImage.width < minWidth) || (loadedImage.height < minHeight)) {
                                    self.$modal.modal('hide')
                                    var toastMessageText = toastMessage.html()
                                    toastMessageText = toastMessageText.replace('%width%', minWidth)
                                    toastMessageText = toastMessageText.replace('%height%', minHeight)
                                    toastMessageWrapper.append(toastMessageText)
                                    toastMessageWrapper.find('.js-min-size-toast').removeClass('hidden')
                                    Toast.init()
                                }
                            }
                        }

                        // set size of preview images after corpper is ready for use
                        self.setCropperPreviews($(this))

                        // initZoomWarning
                        $(this).data('expected-width', self.expectedWidth)
                        $(this).data('expected-height', self.expectedHeight)
                        $(this).data('natural-width', cropperImage.naturalWidth)
                        $(this).data('natural-height', cropperImage.naturalHeight)
                        self.checkForExtensiveZoom($(this))
                    })

                    // Check for zoom higher 100% and throw warning
                    $(this).on('zoom', function () {
                        self.checkForExtensiveZoom($(this))
                    })
                })
                .appendTo(self.$container.$preview)
        })
        .one('hidden.bs.modal', function () {
            if (!self.isImageCropped) {
                // set inputfield to zero so that FormTabs can respond to change
                self.$input.val()
            }
        })
        .modal('show')
}

/**
 * Check for cropper image zoom highther than 100% and trow a warning.
 */
Cropper.prototype.checkForExtensiveZoom = function(imageCropper) {
    const data = imageCropper.cropper('getData')
    const resultWidth = data.width
    const resultHeight = data.height
    const expectedHeight = imageCropper.data('expected-height')
    const expectedWidth = imageCropper.data('expected-width')
    const naturalHeight = imageCropper.data('natural-height')
    const naturalWidth = imageCropper.data('natural-width')

    // @TODO: Text in die Systemtexte zur übersetzung auslagern.
    const zoomErrorMessageTooBig = 'Sie haben das Bild über die Originalgröße skaliert. Bitte verkleinern Sie dieses wieder, damit es zu keinen Qualitätsverlusten kommt.'
    const zoomErrorMessageTooSmall = 'Das skalierte Bild ist kleiner als die benötigte Ausgabegröße, sodass weiße Randflächen entstehen. Bitte beachten Sie das Vorschaubild auf der rechten Seite.'

    /**
     * Compare image in Cropper with exprected image size.
     * If a user uploads an image in the exactly needes size the cropper sometimes throws the error for the extensive zoom.
     * To prevent that we need a little leeway of 2 Pixels.
     */
    if ((resultWidth < (expectedWidth - 2)) || (resultHeight < (expectedHeight - 2))) {
        toggleCropperMessage('error', zoomErrorMessageTooBig, true, false)
    } else {
        toggleCropperMessage('', '', '', true)

        if (resultWidth > (naturalWidth + 2) || resultHeight > (naturalHeight + 2)) {
            toggleCropperMessage('error', zoomErrorMessageTooSmall, false, false)
        } else {
            toggleCropperMessage('', '', '', true)
        }
    }
}

/**
 * Show cropper message.
 */
function toggleCropperMessage(type, message, preventCropping, removeMessage) {
    $('.cropper-messages [data-zoom-error]').remove()
    $('.cropper-messages').append(`<p class="c-cropper-utilities__message c-cropper-utilities__message--${type}" data-zoom-error="">${message}</p>`)

    if (preventCropping) {
        $('.js-crop-image-button').attr('disabled', 'disabled')
    }

    if (removeMessage) {
        $('.cropper-messages [data-zoom-error]').remove()
        $('.js-crop-image-button').removeAttr('disabled')
    }
}

/**
 * Set size of preview images.
 */
Cropper.prototype.setCropperPreviews = function(imageCropper) {
    $('.cropper-previews .c-cropper-utilities__preview').each(function(){
        const previewImageWrapper = $(this).find('div')
        const previewImageHeight = previewImageWrapper.outerHeight()
        const previewImageWidth = previewImageWrapper.outerWidth()
        const previewContainerHeight = $(this).outerHeight()
        const previewContainerWidth = $(this).outerWidth()

        var previewImageStyles = previewImageWrapper.attr('style')
        var previewImageScale = 1

        if (previewImageHeight < previewContainerHeight) {
            var previewImageScale = previewContainerHeight / previewImageHeight
        }

        if (previewImageWidth < previewContainerWidth) {
            var previewImageScale = previewContainerWidth / previewImageWidth
        }

        previewImageStyles = `${previewImageStyles} transform: translateX(-50%) translateY(-50%) scale(${previewImageScale})`
        previewImageWrapper.attr('style', previewImageStyles)
    })
}

/**
 * Create canvas from cropped image and fill in the hidden input with canvas base64 data.
 */
Cropper.prototype.crop = function () {
    this.isImageCropped = true
    const data = this.$container.$preview.children('img').cropper('getData')
    const image_width = data.width
    const image_height = data.height
    const preview_width = Math.min(this.$container.$canvas.data('preview-width'), data.width)
    const preview_height = Math.min(this.$container.$canvas.data('preview-height'), data.height)

        // TODO: getCroppedCanvas seams to only consider one dimension when calculating the maximum size
        // in respect to the aspect ratio and always considers width first, so height is basically ignored!
        // To set a maximum height, no width parameter should be set.
        // Example of current wrong behavior:
        // source of 200x300 with resize to 150x200 results in 150x225 => WRONG (should be: 133x200)
        // source of 200x300 with resize to 200x150 results in 200x300 => WRONG (should be: 100x150)
        // This is an issue with cropper, not this library
    const image_canvas = this.$container.$preview.children('img').cropper('getCroppedCanvas', {
        width: image_width,
        height: image_height,
        fillColor: '#FFFFFF',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'
    })

    const preview_canvas = this.$container.$preview.children('img').cropper('getCroppedCanvas', {
        width: preview_width,
        height: preview_height,
        fillColor: '#FFFFFF',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'
    })

    // fill canvas preview container with cropped image
    this.$container.$canvas.html(preview_canvas)
    this.$container.$actions.hide()

    // fill input with base64 cropped image
    this.$input.val(image_canvas.toDataURL(this.$el.data('mimetype'), this.$el.data('quality')))
    this.$input.trigger('change') // for FormTabs

    // hide the modal
    this.$modal.modal('hide')
}

/**
 * Create canvas from image during rendering.
 */
Cropper.prototype.render = function () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()
    const cropper = this
    image.onload = function() {
        ctx.drawImage(image, 0, 0, cropper.$container.$canvas.data('preview-width'), cropper.$container.$canvas.data('preview-height'))
    }
    image.src = this.$input.val()
    this.$container.$canvas.html(canvas)
}

// initCropper :: jQuery Elem -> Cropper
const initCropper = $elem => new Cropper($elem)

export default initCropper
